@import './variable';

@mixin font($font-size: $basefontsize, $font-weight:400, $color: $blackcolor, $line-height:19px,$letter-spacing: 0.05em){
    font: {size: $font-size;weight: $font-weight;}
    color: $color;line-height: $line-height;letter-spacing: $letter-spacing;}

@mixin flexitem($justify-content: center,$align-items: center,$flex-direction:row,$display: flex){
    display: $display;flex-direction: $flex-direction;justify-content: $justify-content;align-items: $align-items;flex-wrap: wrap;}

@mixin cover{object-fit: cover;height: 100%;}

@mixin btn($padding: $padding,$text-transform: $text-transform){
    padding: $padding;text-transform: $text-transform;border-radius: 5px;cursor: pointer;}

@mixin btn-yellow{@include font($font14,600,$whitecolor,$line-height:20px); 
    background: $maincolor;border: 1px solid $maincolor;
    &:focus{outline: none;box-shadow:none}
    &:hover{color: $maincolor;border: 1px solid $maincolor;background-color: $whitecolor;transition: $DefaultTransition;text-decoration: none;
        img{filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);transition: $DefaultTransition;}
    }
}

@mixin btn-white { @include font($font14,600,$maincolor,$line-height:20px);
    background: $whitecolor;border: 1px solid $maincolor;}

@mixin breadcrumb{font-size: 0.875rem;font-weight: 200;color: $textcolor;}

@mixin centerItem($transform:translate(-50%, -50%),$left:50%,$top: 50%,$position: absolute) {
    position: $position;left: $left;top: $top;transform: $transform;}

@mixin banner-background($imgpath,$position:center,$repeat: no-repeat) {
    background: url(#{$imgpath}) $repeat $position;background-size: cover;
}

@mixin hero-banner{
    figure{height: 500px;position: relative;margin:0;
        img{width: 100%;height: 100%;object-position: top;@include cover;}
    }
}

@mixin title{@include font(35px,500,'',50px); font-family:$jostFont;text-align: center;border-bottom: 1px solid $maincolor;margin: 0 auto 1rem;display: table;}

@mixin subtitle{@include font($basefontsize,400,'',26px);text-align: center;margin-bottom: 0.5rem;}

@mixin section($padding: 2rem 0) {padding: $padding;}

@mixin slick-slider{
    .slick-slider{
        margin-bottom: 2rem;margin:0 -6px;
        .slick-slide {height: auto;padding: 10px 6px;}
        .slick-arrow{
            &.slick-prev { left: -35px;top:60px; &:before{opacity: 1;}}
            &.slick-next {right:-35px;top:60px; &:before{opacity: 1;}}
        }
    }
    .slick-arrow {position: absolute;
        width: 40px;height: 40px;border-radius: 0px 5px 5px 0px;background: #eff4fa;
        &::before {content:'';background: url('../images/icons/prev-icon.svg');width: 10px;height: 15px;@include centerItem();}
        &.slick-prev {left:0;&::before {content:'';background: url('../images/icons/prev-icon.svg');}}
        &.slick-next {right:0;&::before {content:'';background: url('../images/icons/next-icon.svg');}}
        &:hover {background: $maincolor;
        &:before{filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(142deg) brightness(110%) contrast(101%);}}
    }
}

@mixin accordion {
    .accordion{
        &:last-child{.accordion-item{border-bottom: 0;}}
    }
    .accordion-item{border-left: none;border-right: none;background-color: #FBFBFB;
        &:first-of-type{border-top-left-radius: 0;border-top-right-radius:0;}
        &:last-of-type{border-bottom-right-radius:0;border-bottom-left-radius:0;padding: 0 1.5rem 0 1.5rem;border-color: #dee2e673;}
        .accordion-body{@include font($font14,400,'',26px);padding: 1rem;
            table{td{padding-right: 6px;}}}
    }
    .accordion-button:not(.collapsed){background-color: #3153A5;color: $whitecolor !important;box-shadow:none;}
    .accordion-button{@include font($font20,400,'',1rem);padding-left: 6px;
        &:focus{box-shadow: none;border-color: transparent;}
        &:after{background-image: none;}
    }
    button.accordion-button.collapsed{
        span.minusicon{display: none;}
        span.plusicon{display: block;} 
    }
    button.accordion-button{
        span.plusicon{display: none;color: $blackcolor;}   
    }
    span.plusicon,span.minusicon{position: absolute;right:1rem;color: $whitecolor;}
    span.minusicon{margin-right: .4rem;}
    .accordion-button:not(.collapsed)::after{background-image: none;}
}

@mixin checkbox-form{
    .select{@include flexitem(space-between,flex-start);flex-wrap: wrap;padding: 0;margin-bottom: 0;

        .form-group{width: 49%;
            .formMargin{
                .lbl{display: none}
            }

            .form-control {border: 1px solid #D9D9D9;font-size: 0.75rem;border-radius: 0.5px;height: 40px;margin-right: 5px;padding-left:10px;background-image: url('../images/icons/down-caret.svg');background-repeat: no-repeat;background-size: 10px;background-position: top 16px right 17px;}   
        }
    }
}


@mixin form {
    .ellipse{position: absolute;top: 0;left: 0;width: 200px;}
    h2{@include font(35px,500,'',50px);font-family: $jostFont;border-bottom: 1px solid $maincolor;display: table;margin: 0 auto;}
    p{@include font($basefontsize,400,$blackcolor,26px);margin-top: 1rem;text-align: center;margin-bottom: 2rem;}
    .form{display: block;width: 100%;

        .form-group {
            label{@include font($basefontsize,400,$blackcolor,26px);margin-bottom: 0;}

            .cust-select{display: flex;position: relative;
                .std-code{width: 75px;background-color: $whitecolor;display: flex;align-items: center;padding-left: 1rem;border: 1px solid #D9D9D9;font-size: $font14;
                    border-radius: 0.5px;border-right: 0;height: 40px;}
                .formMargin{width: 89%;}
                .error-message{margin-left: -75px;}
            }

            .lbl{display: none;}
            .form-control{border: 1px solid #D9D9D9;font-size: $font14;border-radius: 0.5px;
                &.txtbox{padding: 12px;height: 40px;}
                &:focus{outline: none;box-shadow: none;border: 1px solid #ced4da;}
            }
            .error-message{@include font($font12,400,#D90312,26px);}
            textarea{width: 100%;background-color: #fff;padding: 12px;font-size: $font14;resize: none;border: 1px solid #d9d9d9;border-radius: 0.5px;
                &:focus-visible{outline: none;}
            }
        }
    }

    .submit{
        text-align: center;margin-top: 1.5rem;
        .btn-yellow{width: 350px;padding: .75rem;
            @include btn-yellow;
            @include font($font14,800,$whitecolor,20px);
            &:focus{outline: none;}
        }
    }
}

@mixin social-link {
    .social-link{@include flexitem(flex-start);padding-left: 0;
        li{margin-right: 1.5rem;
        img{width: 40px;height: 40px;}
        }
    }
}

@mixin about-pop{
    @include centerItem(translateY(-50%),auto,50%);right: 0;
}