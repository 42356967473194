@import './variable';
@import './mixin';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{margin: 0;padding: 0;box-sizing: border-box;}
body{background: $whitecolor;font-family: $basefont;font-size: $basefontsize;color: $blackcolor;}
::before,::after{font-family: 'FontAwesome';}
.container,.container-lg,.container-md,.container-sm{max-width: 1326px !important;}
a{text-decoration: none !important;transition: $DefaultTransition;}
li{list-style-type: none;}
.App{position: relative;}
.home .our-services .slider-container{padding: 0 7rem;}

@media screen and (max-width: 991px) {
    .offcanvas{--bs-offcanvas-width: 100%;}
    .navbar-toggler{@include flexitem(space-between,center);border: none;margin: auto;width: 75%;
        .logo{
            .navbar-brand{img{width: 125px;}}
        }
    }
    .offcanvas-header{background-color: $secondarycolor;}
    .offcanvas-body{padding: 1rem 1.5rem;background-color: $secondarycolor;}
    .btn-close{background: transparent url(../images/close-icon.svg) center/1em auto no-repeat;opacity: 1;}

    .nav{@include flexitem($justify-content: space-between,$align-items: '',$flex-direction: column);
        .top-nav{justify-content: flex-end;margin-bottom: 2rem;margin-right: 0 !important;
            .call{@include flexitem;margin-right: 20px;}
            .time{@include flexitem;}
            .nav-link{@include font;}
        }
        .bottom-nav{align-items: center;
            .nav-item{margin: 0 2rem;
                .nav-link{@include font($basefontsize,500);}
                .btn-yellow{@include btn-yellow;@include btn(0.75rem 1.5rem,uppercase);
                    @include flexitem('',flex-end); img{margin-right: 10px;}
                }
                &:last-child{margin-right: 0;padding-right: 8px;}
                &.active{.nav-link{color: $secondarycolor;}}
            }
        }
    }
}

//Header CSS
header{
    nav{ position: absolute !important;border-bottom-right-radius: 100px;border-bottom-left-radius: 100px;padding-bottom: 0 !important;box-shadow: 0px 7px 0px #FBBA00;background: #fff;z-index: 1;width: 100%;

        #navbarSupportedContent{
            .inner-nav{@include flexitem(space-between,stretch);width: 100%;
                .nav{@include flexitem($justify-content: space-between,$align-items: '',$flex-direction: column);
                    .top-nav{justify-content: flex-end;margin-bottom: 1rem;margin-right: 0 !important;margin-bottom: 0;
                        .call{@include flexitem;margin-right: 2rem;}
                        .time{@include flexitem;margin-right: 2rem;}
                        .nav-link{@include font('',500);}
                    }
                    .bottom-nav{align-items: center;
                        .nav-item{margin: 0 2rem;position: relative;display: inline-block;
                            .nav-link{@include font($basefontsize,500);padding: 2.5rem 0.5rem}
                            .btn-yellow{@include btn-yellow;@include btn(0.75rem 1.5rem,uppercase);
                                @include flexitem('',flex-end); img{margin-right: 10px;}
                            }
                            &:last-child{margin-right: 0;}
                            &.active{.nav-link{color: #0f49d7;}}
                            &:hover{.nav-link{color: #0f49d7;}
                             .dropdown-content {display: block;}
                            }

                            .dropdown-content {
                                display: none;position: absolute;background-color: $whitecolor;min-width: 290px;box-shadow: 0px 7px 0px $maincolor;top: 100px;z-index: 1;
                                border-radius: 0px 0px 20px 20px;

                                a{@include font('',400,$blackcolor,26px);text-decoration: none;padding: 12px 30px;display: block;border-bottom: 0.5px solid $maincolor;
                                    &:hover {background-color: $maincolor;color: $whitecolor;}
                                    &:last-child{border-bottom: none;border-radius: 0px 0px 20px 20px;}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.rcb-btn {width: 50px;
    // border-radius: 5px;
    // background: #e1e5e8;
    // writing-mode: vertical-lr;
    // padding: 5px;
    // box-shadow: 0px 0px 5px 1px #a8a6a2;
    z-index: 99;font-size: 1rem;
    font-weight: 600;
    img{width: 100%;}
    &:hover{cursor: pointer;}
    a{color: $whitecolor;}
}

// @keyframes blinker{
//     0%{transform: scale(1);}
//     50%{transform: scale(1.2);}
//     100%{transform: scale(1);}
// }


//Footer CSS
footer{ background-color: $secondarycolor;
    .footer-list {@include flexitem(space-between,flex-start);margin: 0;color: $whitecolor;padding: 2rem 0;

        >li {position: relative;
            &.social{width: 25%;}
            &:nth-child(2) {width: 50%;
                ul{@include flexitem(space-between);
                    .AddressFooter{
                        .address{padding-left: 0;
                            li{@include flexitem(flex-start); @include font('','',$whitecolor,26px); 
                                padding-left: 2rem;margin-bottom: 1rem;
                                .Location{position: absolute;left: 2rem;top: 5px;}
                            }
                        }
                        @include social-link;
                    }
                }
            }
            &:last-child{width: 25%;
                .ContactFooter{
                    .footer-link{padding-left: 0;
                        li{margin-bottom: 1rem;
                            .Icon{margin-right: 1rem;}
                            a{@include font('',400,$whitecolor,26px); text-decoration: none;}
                            &:last-child{margin-bottom: 0;}
                        }
                    }
                }
            }
        }
    }

    .copyright{margin-bottom:0;background-color: $whitecolor;padding: 5px 0;
        .container{@include flexitem(space-between);}
        li{@include font($font14); a{color: $blackcolor;text-decoration: none;}}
    }
}


.breadcrumb-nav{
    .breadcrumb{margin-bottom: 0;
      li{@include font($font12,400,$blackcolor,26px);
        a{@include font($font12,400,$blackcolor,26px);text-decoration: none;
            &:hover{color: $maincolor;}
        }
        +li::before{padding: 8px;content: '|';}
        &.active{@include font($font12,500,$secondarycolor,26px);}
      }
    }
}

.banner{@include hero-banner;}
h2{@include title;}

.welcome{@include section;
    ul{@include flexitem('',stretch);margin-bottom:0;padding-left: 0;
        li{ width: 50%;
            &:first-child{padding-right: 1rem;
                figure{margin-bottom: 0;width: 100%;padding-right: 2rem;
                    img{width: 100%;}
                }
            }
            &:last-child{padding-left: 1rem;@include flexitem(space-between,space-between,column);
                h5{@include font($basefontsize,400,'',26px);}
                h3{@include font(25px,600,'',35px);margin-bottom: 0;position: relative;width: fit-content;
                    &::after{position: absolute;content: ''; border-bottom: 1px solid #FBBA00;width: 100%;bottom: 0;}
                }
                p{@include font($basefontsize,400,'',26px);
                span{font-weight: 700;}
                &:last-child{margin-bottom: 0;}
                }
            }
        }
    }
}


// Home page CSS
.home{
    .home-banner{
        figure{height: 700px;position: relative;margin:0 0 -7px;
            img{width: 100%;height: 100%;object-position: top;@include cover;}
            figcaption {@include centerItem(translate(-50%, -20%),50%);width: 1336px;
            h1{@include font($font50,700,'',70px);
                text-transform: uppercase;text-shadow: 0px 0px 50px #FFFFFF;font-family: $jostFont;padding-bottom: 1.5rem;margin-bottom: 2.5rem;position: relative;display: flex;
                flex-direction: column;width: fit-content;
                animation: slideInTop 1s;
                animation-fill-mode:forwards;

                @keyframes slideInTop {
                    0% {transform: translateY(-100px);}
                    50%{transform: translateY(-50px);}
                    100%{transform: translateY(0);}
                }
                span{display: block;}
            &::after{position: absolute;content: ''; border-bottom: 2px solid #FBBA00;width: 100%;bottom: 0}
            }
            .btn-white{@include btn-white;@include btn(0.75rem 1rem,uppercase);font-size: $basefontsize;display: inline-flex;align-items: center;img{margin-right: 10px;width: 16px;}color: $blackcolor;border-top-right-radius: 0;border-bottom-right-radius: 0;}
            .btn-yellow{@include btn-yellow;@include btn(0.75rem 1rem,uppercase);display: inline-flex;align-items: center;
                img{margin-right: 10px;width: 16px;}
            }
            }
        }
        .slick-slider{
            // margin:0 -6px;
            .slick-slide {height: auto;padding: 0;}
            .slick-arrow{box-shadow: 0px 0px 5px 1px #a8a6a2;
                &.slick-prev{left: 0;border-radius:0;top:60%;
                    &:before{opacity: 1;}}
                &.slick-next{right: 0;border-radius: 0;top:60%;
                    &:before{opacity: 1;}}
            }
        }
        .slick-arrow {position: absolute;width: 40px;height: 40px;background: $whitecolor;
            &::before {content:'';background: url('../images/icons/prev-icon.svg');width: 10px;height: 15px;@include centerItem();}
            &.slick-prev {left:0;z-index: 1;&::before {content:'';background: url('../images/icons/prev-icon.svg');}}
            &.slick-next {right:0;z-index: 1;&::before {content:'';background: url('../images/icons/next-icon.svg');}}
            // &:hover {background: $whitecolor;
            // &:before{filter: invert(0%) sepia(7%) saturate(0%) hue-rotate(96deg) brightness(107%) contrast(150%);}}
            &:hover {background: $maincolor;color: $whitecolor;
            &:before{filter: invert(0%) sepia(7%) saturate(0%) hue-rotate(96deg) brightness(107%) contrast(150%);}}
        }
    }

    .our-services{
        @include section(3rem 0 7rem);
        @include banner-background("../images/our-services-banner.png",bottom);
        h2{margin: 0 auto 3rem;}
        a{
            .card{border: none;border-top: 5px solid $maincolor;position: relative;border-radius: 0;
                .ellipse{width: 150px;}
                .icon{position: absolute;top: 2.5rem;left: 2rem;}
                .card-body{border-radius: 0;height: 315px;padding: 1rem 2rem;
                h3{@include font($font20,500,$blackcolor,30px); margin-top: 2rem;}
                span{@include font($basefontsize,400,$blackcolor,26px);}
                }
            }
        }
        .explore{text-align: center;margin-top: 2rem;
            .btn-yellow{@include btn-yellow;@include btn(0.75rem 1rem,uppercase);img{margin-right: 10px;width: 16px;}}
        }
        .slick-slider{
            margin-bottom: 2rem;margin:0 -6px;
            .slick-slide{height: auto;padding: 23px 15px;
                &.slick-center{.card {transform: scaleY(1.1);
                    .card-body{
                        h3{margin-top: 1.6rem;}
                        span{@include font($basefontsize,400,'',26px);}
                    }
                }}
            }
            .slick-arrow{
                &.slick-prev{left: -50px;border-radius: 5px 0px 0px 5px; &:before{opacity: 1;}}
                &.slick-next{right:-50px;border-radius: 0px 5px 5px 0px; &:before{opacity: 1;}}
            }
        }
        .slick-arrow {position: absolute;
            width: 40px;height: 40px;background: $whitecolor;
            &::before {content:'';
                width: 10px;height: 15px;@include centerItem();}
            &.slick-prev {left:0;&::before {content:'';background: url('../images/icons/prev-icon.svg');}}
            &.slick-next {right:0;&::before {content:'';background: url('../images/icons/next-icon.svg');}}
            &:hover {background: $maincolor;color: $whitecolor;
            &:before{filter: invert(0%) sepia(7%) saturate(0%) hue-rotate(96deg) brightness(107%) contrast(150%);}}
        }
    }

    .welcome{@include section(5rem 0 1rem);
        ul{
            li{
                &:last-child{
                    h5{margin-bottom: 0;}
                    h3{@include font(35px,500,'',50px);font-family: $jostFont;padding-bottom:1.5rem;
                        span{display: block;}
                    }
                    .home-welcome{display: block;
                        p{font-size: 19px;}
                    .btn-yellow{margin-bottom: 1rem;margin-top: 3rem;margin-bottom: 0;
                        @include btn-yellow;@include btn(0.75rem 1.5rem,uppercase);font-weight: 800;
                    }
                    }
                    .about-welcome{display: none}
                }
            }
        }
    }

    .quality-staff{@include section(5rem 0 1rem);
        ul{padding-left: 0; @include flexitem(flex-start,flex-end);
            li{width: 20%;position: relative;overflow: hidden;
                img{width: 100%;}
                .image-overlay {
                    background: $secondarycolor;position: absolute;top: 0;bottom: 0;left: 0;right: 0;opacity: .8;transition: .5s ease;transform: translateY(100%);
                    h2{@include font(24px,600,$whitecolor,35px); @include centerItem; border-bottom: 1px solid $maincolor;display: inline;padding-bottom: 1rem;width: 80%;}
                }
                &:hover{
                    .image-overlay{display: block;transform: translateY(0%);}
                }
            }
        }
    }

    .brands-we-serve{@include section;
        h2{margin: 0 auto 2rem;padding-bottom: 0.5rem;}
        .brand-img{
            figure{height: 100px;background-color: #FFF;border: 0.5px solid #D9D9D9;display: flex;justify-content:center;align-items:center;
                img{width: 70%;height: auto;object-fit: cover;}
            }
        }   
        @include slick-slider;
    }

    .safety-measures{@include section(7rem 0);
        @include banner-background("../images/safety-banner.png",top);
        h2{margin: 0 auto 4rem;padding-bottom: 1rem;}
        ul{ @include flexitem(space-between);padding-left: 0;
            li{width: 218px;height: 218px;background: rgba(49, 83, 165, 0.05);border-radius: 50%;position: relative;
                img{@include centerItem(translate(-50%, -50%),50%,35%);}
                h5{@include font(25px,400,'',35px);position: absolute;white-space: nowrap;width: 100%;height: 100%;@include flexitem(center,flex-end);bottom: 10%;}
            }
        }
    }
}


//ThankYou CSS
.thank-you{
    .thank{@include section;margin-bottom: 5rem;
        .thankyou{width: 800px;background-color: #f5f6fa;margin: auto;padding: 3rem 0 6rem;position: relative;
            .icon{position: absolute;top: 0;left: 0;}
            h2{padding-bottom: 0;margin-bottom: 3rem;}
            p{@include font($font20,500,$blackcolor,30px);text-align: center;margin-bottom: 3rem;
            span{display: block;}}
            .back{text-align: center;margin-top: 1rem;
                .btn-yellow{@include btn-yellow;@include btn(0.75rem 1rem,uppercase);width: 350px;}
            }
        }
    }
}


//About CSS
.about{
    .bread{background-color: $bgColor;}
    .about-section{ 
        @include banner-background("../images/aboutbanner.png",bottom);
        @include section(1rem 0 4rem);margin-bottom: 1rem; 
        .about{margin: 0 auto 1rem;width: 100%;max-width: 878px;
            h2{padding-bottom: 0;}
            p{@include font($basefontsize,400,$blackcolor,26px);
            span{font-weight: 700;}
            }
        }
    }

    .welcome{@include section;
        ul {
            li{
                &:last-child{
                    h5{margin-bottom: 0;}
                    h3{display: flex;padding-bottom:1rem;width: fit-content; span{display: inline;margin-left: 0.5rem;}         
                }
                    .home-welcome{display: none;}
                    .about-welcome{display: block}
                }
            }
        }
    }

    .our-missions{@include section;position: relative;
        .outer-div{padding: 5rem 1rem;
            background: rgb(239,243,255);
            background: linear-gradient(90deg, rgba(239,243,255,1) 70%, rgba(255,255,255,1) 70%);
            position: relative;
            h2{@include font(25px,600,'',35px);border-bottom: 1px solid $maincolor;display: inline;padding-bottom: 0.25rem;}
            .inner-ul{padding-left: 0;width: 75%;
                ul{@include flexitem(flex-start,flex-start);padding-left: 0;margin-top: 1.5rem;
                    li{
                        &:first-child{margin-right: 1.5rem;}
                        &:last-child{width: 65%;
                            h5{@include font($font20,500,'',30px);}
                            p{@include font($basefontsize,400,$blackcolor,26px);}
                        }
                    }
                }
            }
        }
        .half-img {width: 45%;height:auto;position:absolute;top:50%;right:0;transform:translateY(-50%);margin:0;
            img{width: 100%;}
        }
        .our-mission{@include about-pop;width: 50%;}
    }

    .why-choose-us{@include section;
        .outer-div{position: relative;
            figure{width: 60%;}
            .our-mission{@include about-pop;background: $whitecolor;width: 50%;box-shadow: 0px 12px 56px rgba(6, 28, 61, 0.08);
                .faq-accordion{display: block;padding: 1rem 0;
                    h5{font-size: 20px;color: $blackcolor;font-weight: 600;}
                    .accordion-item{
                        &:first-of-type{border-top: 0;}
                        .accordion-header{@include font($font20,500,'',30px);font-family:$basefont;display: block;border-bottom: none;
                        .accordion-button{@include font($font20,500,$blackcolor,30px); padding-left: 15px;border-radius: 0;}
                        }
                         .accordion-body{padding: 15px;background-color: #EEF3FF;
                        p{@include font($basefontsize,400,'',26px);margin-bottom: 0;}}
                    }
                    @include accordion;
                }
            }
        }
    }
}


//Services CSS
.services{
    .bread{background-color: #eff5fb;}
    .services-section{ @include section(1rem 0 8rem);
        @include banner-background("../images/our-services-banner.png",bottom);margin-bottom: 5rem;
        h2{padding-bottom: 0;margin: 0 auto 2.5rem;}
        .services-main{@include flexitem(flex-start,stretch);
            a{width: 24%;margin-right: 1rem;margin-bottom: 3rem;
                .card{border-top: 5px solid $maincolor;position: relative;border-left:none;border-right:none;border-bottom:none;border-radius:0;
                    .ellipse{width: 150px;}
                    .icon{position: absolute;top: 2.5rem;left: 2rem;}
                    .card-body{border-radius: 0;padding: 1rem 2rem;height: 335px;
                        h3{@include font($font20,500,$blackcolor,30px); margin-top: 1rem;margin-bottom: 1.25rem;}
                        span{@include font($basefontsize,400,$blackcolor,26px);}
                    }
                }
                &:nth-child(4),&:last-child{margin-right: 0;}
            }
            .book-card{margin-right: 0;width: 24%;margin-bottom:3rem;border: none;border-radius: 0;
                .card-body{@include flexitem(flex-end,center,column);padding: 0 2rem;
                    h3{@include font(1.2rem,500,$secondarycolor,30px);margin-bottom: 1.5rem;}
                    .btn-yellow{margin: 0 0 1rem;width: 100%;
                        @include btn-yellow;@include btn(0.75rem 1.25rem,uppercase);@include flexitem(center,flex-end); flex-wrap: nowrap;
                        img{margin-right: 10px;}
                    }
                    ul{padding-left: 0;display: flex;
                        li{
                            img{margin-right: .5rem;}
                            a{display: block;text-decoration: none;margin-bottom: 0.5rem; @include font($basefontsize,500);width: auto;}
                        }
                    }
                }
            }
        }
    }
}


//Clients CSS
.clients{
    .clients-section{ @include section(1rem 0 4rem);
        h2{padding-bottom: 0;margin: 0 auto 2.5rem;}
        .clients-main{@include flexitem(space-between);
            .card{position: relative;width: 32%;margin-bottom: 3rem;border:none;box-shadow: 0px 12px 40px rgba(6, 28, 61, 0.04);
                .card-body{@include flexitem(flex-start);flex-wrap:nowrap;position: relative;padding-left: 60px;margin: 1rem;
                    img{margin-right: 1.5rem;position: absolute;left: 0;}
                    h3{@include font($basefontsize,600,'',26px); margin-bottom: 0;}
                }
            }
        }
    }
}


//Contact CSS
.contact{
    .contact-section{@include section(1rem 0 4rem);
        .outer-div{@include flexitem(flex-start,flex-start);
            .get-in-touch{@include section(5rem 3rem);width: 40%;height: 750px;
                @include banner-background("../images/get-in-touch-bg.png");
                h2{@include font(35px,500,$maincolor,50px);margin: 0 0 2rem;}
                .footer-link{padding-left: 0;
                    li{margin-bottom: 1.5rem;@include flexitem(flex-start,flex-start);flex-wrap:nowrap;
                        .Icon{margin-right: 1rem;font-size: 16px;}
                        div{
                            h5{@include font($font14,500,$whitecolor,20px);border-bottom: 1px solid $maincolor;display: table;opacity: 0.8;padding-bottom: 0.1rem;}
                            a{@include font($basefontsize,400,$whitecolor,26px);text-decoration: none;display: block;margin-bottom: 0.25rem;}
                            p{@include font($basefontsize,400,$whitecolor,26px);display: block;width: 75%;}
                        }
                    }
                }
                .connect{
                    @include flexitem(flex-start);margin-bottom:0.5rem;
                    hr{width: 32px;border-top: 2px solid #FBBA00;opacity: 1;margin-right: 1rem}
                    h5{@include font($basefontsize,400,$maincolor,24px);margin-bottom: 0;}
                }
                @include social-link;
            }
            .contact-form{@include flexitem(center,'',column);flex-wrap: nowrap;
                background-color: #F5F6FA;width: 60%;height: 750px;position: relative;
                @include section(5rem 3rem);
                @include form;
            }
        } 
    }
}


//Book An Appointment CSS
.book-an-appointment{
    .main-div{@include banner-background("../images/book-appointment-bg.png");
        .book-an-appointment-section{@include section(2rem 0 4rem);
            .book-an-appointment-form{background-color: #F5F6FA;width: 65%;position: relative;margin: auto;
                @include section(4rem 5rem 5rem);
                @include form;
                .calender{position: absolute;z-index: 1;top: 3rem;left: 3rem;}
                .form-group{
                    .form-select {border-radius: 5px;height: 40px;border: 1px solid #D9D9D9;font-size: $font14;border-radius: 0.5px;background-image: url('../images/icons/down-caret.svg');background-size: 10px;background-position: top 16px right 17px;
                        &:focus{box-shadow: none;}
                    }
                }

                @include checkbox-form;
                input[type="text"]{
                    ::-webkit-input-placeholder{font-size: $font14;}
                }
                input[type="time"]{
                    &::-webkit-calendar-picker-indicator{
                        background-image: url('../images/icons/down-caret.svg');
                        background-size: 10px;
                        background-position: top 16px right 17px;
                    }
                }
            }
        }
    }
}

//Book Appointment Molecule CSS
.book-appointment{width: 25%;
    .card{border: none;
        .card-body{@include flexitem(flex-end,center,column);padding: 1.5rem;border: 1px solid #dee2e6;
            h5{@include font($font20,500,$secondarycolor,30px);margin-bottom: 1.5rem;}
            .btn-yellow{ margin-bottom: 1rem;width: 100%;
                @include btn-yellow;@include btn(0.75rem 1.5rem,uppercase);@include flexitem(center,flex-end); flex-wrap: nowrap;
                img{margin-right: 10px;}
            }
            ul{padding-left: 0;display: flex;margin-bottom: 0;
                li{
                    img{margin-right: .5rem;}
                    a{display: block;text-decoration: none;margin-bottom: 0.5rem; @include font($basefontsize,500);}
                    &::marker {color: red;font-size: 1.5em;}
                }
            }
        }
    }
}

//Service Details Page CSS
.air-condition{
    .ac{@include section(1rem 0);margin-bottom: 5rem;
        .ac-details{position: relative;
            @include flexitem('',flex-start);flex-wrap:nowrap;
            .content{padding-right: 5rem;width: 75%;
                h2{padding-bottom: 0;margin-left: 0;text-align: left;}
                p{@include font($basefontsize,400,$blackcolor,26px);}
                ul{margin-bottom: 3rem;
                    li{list-style-type: disc;margin-bottom: 1rem;@include font($basefontsize,400,$blackcolor,26px);
                    span{font-weight: 700;}
                    }
                }
            }
        }
    }
}