@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: #000;
}

::before, ::after {
  font-family: "FontAwesome";
}

.container, .container-lg, .container-md, .container-sm {
  max-width: 1326px !important;
}

a {
  text-decoration: none !important;
  transition: all 0.5s ease-in-out;
}

li {
  list-style-type: none;
}

.App {
  position: relative;
}

.home .our-services .slider-container {
  padding: 0 7rem;
}

@media screen and (max-width: 991px) {
  .offcanvas {
    --bs-offcanvas-width: 100%;
  }
  .navbar-toggler {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border: none;
    margin: auto;
    width: 75%;
  }
  .navbar-toggler .logo .navbar-brand img {
    width: 125px;
  }
  .offcanvas-header {
    background-color: #3153A5;
  }
  .offcanvas-body {
    padding: 1rem 1.5rem;
    background-color: #3153A5;
  }
  .btn-close {
    background: transparent url(../images/close-icon.svg) center/1em auto no-repeat;
    opacity: 1;
  }
  .nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: "";
    flex-wrap: wrap;
  }
  .nav .top-nav {
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-right: 0 !important;
  }
  .nav .top-nav .call {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 20px;
  }
  .nav .top-nav .time {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .nav .top-nav .nav-link {
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    line-height: 19px;
    letter-spacing: 0.05em;
  }
  .nav .bottom-nav {
    align-items: center;
  }
  .nav .bottom-nav .nav-item {
    margin: 0 2rem;
  }
  .nav .bottom-nav .nav-item .nav-link {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    line-height: 19px;
    letter-spacing: 0.05em;
  }
  .nav .bottom-nav .nav-item .btn-yellow {
    font-size: 0.875rem;
    font-weight: 600;
    color: #fff;
    line-height: 20px;
    letter-spacing: 0.05em;
    background: #FBBA00;
    border: 1px solid #FBBA00;
    padding: 0.75rem 1.5rem;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: "";
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .nav .bottom-nav .nav-item .btn-yellow:focus {
    outline: none;
    box-shadow: none;
  }
  .nav .bottom-nav .nav-item .btn-yellow:hover {
    color: #FBBA00;
    border: 1px solid #FBBA00;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    text-decoration: none;
  }
  .nav .bottom-nav .nav-item .btn-yellow:hover img {
    filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
    transition: all 0.5s ease-in-out;
  }
  .nav .bottom-nav .nav-item .btn-yellow img {
    margin-right: 10px;
  }
  .nav .bottom-nav .nav-item:last-child {
    margin-right: 0;
    padding-right: 8px;
  }
  .nav .bottom-nav .nav-item.active .nav-link {
    color: #3153A5;
  }
}
header nav {
  position: absolute !important;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  padding-bottom: 0 !important;
  box-shadow: 0px 7px 0px #FBBA00;
  background: #fff;
  z-index: 1;
  width: 100%;
}
header nav #navbarSupportedContent .inner-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
}
header nav #navbarSupportedContent .inner-nav .nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: "";
  flex-wrap: wrap;
}
header nav #navbarSupportedContent .inner-nav .nav .top-nav {
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-right: 0 !important;
  margin-bottom: 0;
}
header nav #navbarSupportedContent .inner-nav .nav .top-nav .call {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 2rem;
}
header nav #navbarSupportedContent .inner-nav .nav .top-nav .time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 2rem;
}
header nav #navbarSupportedContent .inner-nav .nav .top-nav .nav-link {
  font-size: "";
  font-weight: 500;
  color: #000;
  line-height: 19px;
  letter-spacing: 0.05em;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav {
  align-items: center;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item {
  margin: 0 2rem;
  position: relative;
  display: inline-block;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .nav-link {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  line-height: 19px;
  letter-spacing: 0.05em;
  padding: 2.5rem 0.5rem;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .btn-yellow {
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #FBBA00;
  border: 1px solid #FBBA00;
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: "";
  align-items: flex-end;
  flex-wrap: wrap;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .btn-yellow:focus {
  outline: none;
  box-shadow: none;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .btn-yellow:hover {
  color: #FBBA00;
  border: 1px solid #FBBA00;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .btn-yellow:hover img {
  filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
  transition: all 0.5s ease-in-out;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .btn-yellow img {
  margin-right: 10px;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item:last-child {
  margin-right: 0;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item.active .nav-link {
  color: #0f49d7;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item:hover .nav-link {
  color: #0f49d7;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item:hover .dropdown-content {
  display: block;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 290px;
  box-shadow: 0px 7px 0px #FBBA00;
  top: 100px;
  z-index: 1;
  border-radius: 0px 0px 20px 20px;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .dropdown-content a {
  font-size: "";
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
  text-decoration: none;
  padding: 12px 30px;
  display: block;
  border-bottom: 0.5px solid #FBBA00;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .dropdown-content a:hover {
  background-color: #FBBA00;
  color: #fff;
}
header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item .dropdown-content a:last-child {
  border-bottom: none;
  border-radius: 0px 0px 20px 20px;
}

.rcb-btn {
  width: 50px;
  z-index: 99;
  font-size: 1rem;
  font-weight: 600;
}
.rcb-btn img {
  width: 100%;
}
.rcb-btn:hover {
  cursor: pointer;
}
.rcb-btn a {
  color: #fff;
}

footer {
  background-color: #3153A5;
}
footer .footer-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0;
  color: #fff;
  padding: 2rem 0;
}
footer .footer-list > li {
  position: relative;
}
footer .footer-list > li.social {
  width: 25%;
}
footer .footer-list > li:nth-child(2) {
  width: 50%;
}
footer .footer-list > li:nth-child(2) ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
footer .footer-list > li:nth-child(2) ul .AddressFooter .address {
  padding-left: 0;
}
footer .footer-list > li:nth-child(2) ul .AddressFooter .address li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: "";
  font-weight: "";
  color: #fff;
  line-height: 26px;
  letter-spacing: 0.05em;
  padding-left: 2rem;
  margin-bottom: 1rem;
}
footer .footer-list > li:nth-child(2) ul .AddressFooter .address li .Location {
  position: absolute;
  left: 2rem;
  top: 5px;
}
footer .footer-list > li:nth-child(2) ul .AddressFooter .social-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0;
}
footer .footer-list > li:nth-child(2) ul .AddressFooter .social-link li {
  margin-right: 1.5rem;
}
footer .footer-list > li:nth-child(2) ul .AddressFooter .social-link li img {
  width: 40px;
  height: 40px;
}
footer .footer-list > li:last-child {
  width: 25%;
}
footer .footer-list > li:last-child .ContactFooter .footer-link {
  padding-left: 0;
}
footer .footer-list > li:last-child .ContactFooter .footer-link li {
  margin-bottom: 1rem;
}
footer .footer-list > li:last-child .ContactFooter .footer-link li .Icon {
  margin-right: 1rem;
}
footer .footer-list > li:last-child .ContactFooter .footer-link li a {
  font-size: "";
  font-weight: 400;
  color: #fff;
  line-height: 26px;
  letter-spacing: 0.05em;
  text-decoration: none;
}
footer .footer-list > li:last-child .ContactFooter .footer-link li:last-child {
  margin-bottom: 0;
}
footer .copyright {
  margin-bottom: 0;
  background-color: #fff;
  padding: 5px 0;
}
footer .copyright .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
footer .copyright li {
  font-size: 0.875rem;
  font-weight: 400;
  color: #000;
  line-height: 19px;
  letter-spacing: 0.05em;
}
footer .copyright li a {
  color: #000;
  text-decoration: none;
}

.breadcrumb-nav .breadcrumb {
  margin-bottom: 0;
}
.breadcrumb-nav .breadcrumb li {
  font-size: 0.75rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
}
.breadcrumb-nav .breadcrumb li a {
  font-size: 0.75rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
  text-decoration: none;
}
.breadcrumb-nav .breadcrumb li a:hover {
  color: #FBBA00;
}
.breadcrumb-nav .breadcrumb li + li::before {
  padding: 8px;
  content: "|";
}
.breadcrumb-nav .breadcrumb li.active {
  font-size: 0.75rem;
  font-weight: 500;
  color: #3153A5;
  line-height: 26px;
  letter-spacing: 0.05em;
}

.banner figure {
  height: 500px;
  position: relative;
  margin: 0;
}
.banner figure img {
  width: 100%;
  height: 100%;
  -o-object-position: top;
     object-position: top;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}

h2 {
  font-size: 35px;
  font-weight: 500;
  color: "";
  line-height: 50px;
  letter-spacing: 0.05em;
  font-family: "Jost", sans-serif;
  text-align: center;
  border-bottom: 1px solid #FBBA00;
  margin: 0 auto 1rem;
  display: table;
}

.welcome {
  padding: 2rem 0;
}
.welcome ul {
  display: flex;
  flex-direction: row;
  justify-content: "";
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.welcome ul li {
  width: 50%;
}
.welcome ul li:first-child {
  padding-right: 1rem;
}
.welcome ul li:first-child figure {
  margin-bottom: 0;
  width: 100%;
  padding-right: 2rem;
}
.welcome ul li:first-child figure img {
  width: 100%;
}
.welcome ul li:last-child {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  flex-wrap: wrap;
}
.welcome ul li:last-child h5 {
  font-size: 1rem;
  font-weight: 400;
  color: "";
  line-height: 26px;
  letter-spacing: 0.05em;
}
.welcome ul li:last-child h3 {
  font-size: 25px;
  font-weight: 600;
  color: "";
  line-height: 35px;
  letter-spacing: 0.05em;
  margin-bottom: 0;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.welcome ul li:last-child h3::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #FBBA00;
  width: 100%;
  bottom: 0;
}
.welcome ul li:last-child p {
  font-size: 1rem;
  font-weight: 400;
  color: "";
  line-height: 26px;
  letter-spacing: 0.05em;
}
.welcome ul li:last-child p span {
  font-weight: 700;
}
.welcome ul li:last-child p:last-child {
  margin-bottom: 0;
}

.home .home-banner figure {
  height: 700px;
  position: relative;
  margin: 0 0 -7px;
}
.home .home-banner figure img {
  width: 100%;
  height: 100%;
  -o-object-position: top;
     object-position: top;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.home .home-banner figure figcaption {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -20%);
  width: 1336px;
}
.home .home-banner figure figcaption h1 {
  font-size: 3.125rem;
  font-weight: 700;
  color: "";
  line-height: 70px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-shadow: 0px 0px 50px #FFFFFF;
  font-family: "Jost", sans-serif;
  padding-bottom: 1.5rem;
  margin-bottom: 2.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-animation: slideInTop 1s;
          animation: slideInTop 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@-webkit-keyframes slideInTop {
  0% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInTop {
  0% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
.home .home-banner figure figcaption h1 span {
  display: block;
}
.home .home-banner figure figcaption h1::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #FBBA00;
  width: 100%;
  bottom: 0;
}
.home .home-banner figure figcaption .btn-white {
  font-size: 0.875rem;
  font-weight: 600;
  color: #FBBA00;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #fff;
  border: 1px solid #FBBA00;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  color: #000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.home .home-banner figure figcaption .btn-white img {
  margin-right: 10px;
  width: 16px;
}
.home .home-banner figure figcaption .btn-yellow {
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #FBBA00;
  border: 1px solid #FBBA00;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.home .home-banner figure figcaption .btn-yellow:focus {
  outline: none;
  box-shadow: none;
}
.home .home-banner figure figcaption .btn-yellow:hover {
  color: #FBBA00;
  border: 1px solid #FBBA00;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.home .home-banner figure figcaption .btn-yellow:hover img {
  filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
  transition: all 0.5s ease-in-out;
}
.home .home-banner figure figcaption .btn-yellow img {
  margin-right: 10px;
  width: 16px;
}
.home .home-banner .slick-slider .slick-slide {
  height: auto;
  padding: 0;
}
.home .home-banner .slick-slider .slick-arrow {
  box-shadow: 0px 0px 5px 1px #a8a6a2;
}
.home .home-banner .slick-slider .slick-arrow.slick-prev {
  left: 0;
  border-radius: 0;
  top: 60%;
}
.home .home-banner .slick-slider .slick-arrow.slick-prev:before {
  opacity: 1;
}
.home .home-banner .slick-slider .slick-arrow.slick-next {
  right: 0;
  border-radius: 0;
  top: 60%;
}
.home .home-banner .slick-slider .slick-arrow.slick-next:before {
  opacity: 1;
}
.home .home-banner .slick-arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #fff;
}
.home .home-banner .slick-arrow::before {
  content: "";
  background: url("../images/icons/prev-icon.svg");
  width: 10px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.home .home-banner .slick-arrow.slick-prev {
  left: 0;
  z-index: 1;
}
.home .home-banner .slick-arrow.slick-prev::before {
  content: "";
  background: url("../images/icons/prev-icon.svg");
}
.home .home-banner .slick-arrow.slick-next {
  right: 0;
  z-index: 1;
}
.home .home-banner .slick-arrow.slick-next::before {
  content: "";
  background: url("../images/icons/next-icon.svg");
}
.home .home-banner .slick-arrow:hover {
  background: #FBBA00;
  color: #fff;
}
.home .home-banner .slick-arrow:hover:before {
  filter: invert(0%) sepia(7%) saturate(0%) hue-rotate(96deg) brightness(107%) contrast(150%);
}
.home .our-services {
  padding: 3rem 0 7rem;
  background: url(../images/our-services-banner.png) no-repeat bottom;
  background-size: cover;
}
.home .our-services h2 {
  margin: 0 auto 3rem;
}
.home .our-services a .card {
  border: none;
  border-top: 5px solid #FBBA00;
  position: relative;
  border-radius: 0;
}
.home .our-services a .card .ellipse {
  width: 150px;
}
.home .our-services a .card .icon {
  position: absolute;
  top: 2.5rem;
  left: 2rem;
}
.home .our-services a .card .card-body {
  border-radius: 0;
  height: 315px;
  padding: 1rem 2rem;
}
.home .our-services a .card .card-body h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  line-height: 30px;
  letter-spacing: 0.05em;
  margin-top: 2rem;
}
.home .our-services a .card .card-body span {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
}
.home .our-services .explore {
  text-align: center;
  margin-top: 2rem;
}
.home .our-services .explore .btn-yellow {
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #FBBA00;
  border: 1px solid #FBBA00;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
}
.home .our-services .explore .btn-yellow:focus {
  outline: none;
  box-shadow: none;
}
.home .our-services .explore .btn-yellow:hover {
  color: #FBBA00;
  border: 1px solid #FBBA00;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.home .our-services .explore .btn-yellow:hover img {
  filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
  transition: all 0.5s ease-in-out;
}
.home .our-services .explore .btn-yellow img {
  margin-right: 10px;
  width: 16px;
}
.home .our-services .slick-slider {
  margin-bottom: 2rem;
  margin: 0 -6px;
}
.home .our-services .slick-slider .slick-slide {
  height: auto;
  padding: 23px 15px;
}
.home .our-services .slick-slider .slick-slide.slick-center .card {
  transform: scaleY(1.1);
}
.home .our-services .slick-slider .slick-slide.slick-center .card .card-body h3 {
  margin-top: 1.6rem;
}
.home .our-services .slick-slider .slick-slide.slick-center .card .card-body span {
  font-size: 1rem;
  font-weight: 400;
  color: "";
  line-height: 26px;
  letter-spacing: 0.05em;
}
.home .our-services .slick-slider .slick-arrow.slick-prev {
  left: -50px;
  border-radius: 5px 0px 0px 5px;
}
.home .our-services .slick-slider .slick-arrow.slick-prev:before {
  opacity: 1;
}
.home .our-services .slick-slider .slick-arrow.slick-next {
  right: -50px;
  border-radius: 0px 5px 5px 0px;
}
.home .our-services .slick-slider .slick-arrow.slick-next:before {
  opacity: 1;
}
.home .our-services .slick-arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #fff;
}
.home .our-services .slick-arrow::before {
  content: "";
  width: 10px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.home .our-services .slick-arrow.slick-prev {
  left: 0;
}
.home .our-services .slick-arrow.slick-prev::before {
  content: "";
  background: url("../images/icons/prev-icon.svg");
}
.home .our-services .slick-arrow.slick-next {
  right: 0;
}
.home .our-services .slick-arrow.slick-next::before {
  content: "";
  background: url("../images/icons/next-icon.svg");
}
.home .our-services .slick-arrow:hover {
  background: #FBBA00;
  color: #fff;
}
.home .our-services .slick-arrow:hover:before {
  filter: invert(0%) sepia(7%) saturate(0%) hue-rotate(96deg) brightness(107%) contrast(150%);
}
.home .welcome {
  padding: 5rem 0 1rem;
}
.home .welcome ul li:last-child h5 {
  margin-bottom: 0;
}
.home .welcome ul li:last-child h3 {
  font-size: 35px;
  font-weight: 500;
  color: "";
  line-height: 50px;
  letter-spacing: 0.05em;
  font-family: "Jost", sans-serif;
  padding-bottom: 1.5rem;
}
.home .welcome ul li:last-child h3 span {
  display: block;
}
.home .welcome ul li:last-child .home-welcome {
  display: block;
}
.home .welcome ul li:last-child .home-welcome p {
  font-size: 19px;
}
.home .welcome ul li:last-child .home-welcome .btn-yellow {
  margin-bottom: 1rem;
  margin-top: 3rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #FBBA00;
  border: 1px solid #FBBA00;
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 800;
}
.home .welcome ul li:last-child .home-welcome .btn-yellow:focus {
  outline: none;
  box-shadow: none;
}
.home .welcome ul li:last-child .home-welcome .btn-yellow:hover {
  color: #FBBA00;
  border: 1px solid #FBBA00;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.home .welcome ul li:last-child .home-welcome .btn-yellow:hover img {
  filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
  transition: all 0.5s ease-in-out;
}
.home .welcome ul li:last-child .about-welcome {
  display: none;
}
.home .quality-staff {
  padding: 5rem 0 1rem;
}
.home .quality-staff ul {
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
}
.home .quality-staff ul li {
  width: 20%;
  position: relative;
  overflow: hidden;
}
.home .quality-staff ul li img {
  width: 100%;
}
.home .quality-staff ul li .image-overlay {
  background: #3153A5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  transition: 0.5s ease;
  transform: translateY(100%);
}
.home .quality-staff ul li .image-overlay h2 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  line-height: 35px;
  letter-spacing: 0.05em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-bottom: 1px solid #FBBA00;
  display: inline;
  padding-bottom: 1rem;
  width: 80%;
}
.home .quality-staff ul li:hover .image-overlay {
  display: block;
  transform: translateY(0%);
}
.home .brands-we-serve {
  padding: 2rem 0;
}
.home .brands-we-serve h2 {
  margin: 0 auto 2rem;
  padding-bottom: 0.5rem;
}
.home .brands-we-serve .brand-img figure {
  height: 100px;
  background-color: #FFF;
  border: 0.5px solid #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .brands-we-serve .brand-img figure img {
  width: 70%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.home .brands-we-serve .slick-slider {
  margin-bottom: 2rem;
  margin: 0 -6px;
}
.home .brands-we-serve .slick-slider .slick-slide {
  height: auto;
  padding: 10px 6px;
}
.home .brands-we-serve .slick-slider .slick-arrow.slick-prev {
  left: -35px;
  top: 60px;
}
.home .brands-we-serve .slick-slider .slick-arrow.slick-prev:before {
  opacity: 1;
}
.home .brands-we-serve .slick-slider .slick-arrow.slick-next {
  right: -35px;
  top: 60px;
}
.home .brands-we-serve .slick-slider .slick-arrow.slick-next:before {
  opacity: 1;
}
.home .brands-we-serve .slick-arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 0px 5px 5px 0px;
  background: #eff4fa;
}
.home .brands-we-serve .slick-arrow::before {
  content: "";
  background: url("../images/icons/prev-icon.svg");
  width: 10px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.home .brands-we-serve .slick-arrow.slick-prev {
  left: 0;
}
.home .brands-we-serve .slick-arrow.slick-prev::before {
  content: "";
  background: url("../images/icons/prev-icon.svg");
}
.home .brands-we-serve .slick-arrow.slick-next {
  right: 0;
}
.home .brands-we-serve .slick-arrow.slick-next::before {
  content: "";
  background: url("../images/icons/next-icon.svg");
}
.home .brands-we-serve .slick-arrow:hover {
  background: #FBBA00;
}
.home .brands-we-serve .slick-arrow:hover:before {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(142deg) brightness(110%) contrast(101%);
}
.home .safety-measures {
  padding: 7rem 0;
  background: url(../images/safety-banner.png) no-repeat top;
  background-size: cover;
}
.home .safety-measures h2 {
  margin: 0 auto 4rem;
  padding-bottom: 1rem;
}
.home .safety-measures ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0;
}
.home .safety-measures ul li {
  width: 218px;
  height: 218px;
  background: rgba(49, 83, 165, 0.05);
  border-radius: 50%;
  position: relative;
}
.home .safety-measures ul li img {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
}
.home .safety-measures ul li h5 {
  font-size: 25px;
  font-weight: 400;
  color: "";
  line-height: 35px;
  letter-spacing: 0.05em;
  position: absolute;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  bottom: 10%;
}

.thank-you .thank {
  padding: 2rem 0;
  margin-bottom: 5rem;
}
.thank-you .thank .thankyou {
  width: 800px;
  background-color: #f5f6fa;
  margin: auto;
  padding: 3rem 0 6rem;
  position: relative;
}
.thank-you .thank .thankyou .icon {
  position: absolute;
  top: 0;
  left: 0;
}
.thank-you .thank .thankyou h2 {
  padding-bottom: 0;
  margin-bottom: 3rem;
}
.thank-you .thank .thankyou p {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 3rem;
}
.thank-you .thank .thankyou p span {
  display: block;
}
.thank-you .thank .thankyou .back {
  text-align: center;
  margin-top: 1rem;
}
.thank-you .thank .thankyou .back .btn-yellow {
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #FBBA00;
  border: 1px solid #FBBA00;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  width: 350px;
}
.thank-you .thank .thankyou .back .btn-yellow:focus {
  outline: none;
  box-shadow: none;
}
.thank-you .thank .thankyou .back .btn-yellow:hover {
  color: #FBBA00;
  border: 1px solid #FBBA00;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.thank-you .thank .thankyou .back .btn-yellow:hover img {
  filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
  transition: all 0.5s ease-in-out;
}

.about .bread {
  background-color: #f7fafd;
}
.about .about-section {
  background: url(../images/aboutbanner.png) no-repeat bottom;
  background-size: cover;
  padding: 1rem 0 4rem;
  margin-bottom: 1rem;
}
.about .about-section .about {
  margin: 0 auto 1rem;
  width: 100%;
  max-width: 878px;
}
.about .about-section .about h2 {
  padding-bottom: 0;
}
.about .about-section .about p {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
}
.about .about-section .about p span {
  font-weight: 700;
}
.about .welcome {
  padding: 2rem 0;
}
.about .welcome ul li:last-child h5 {
  margin-bottom: 0;
}
.about .welcome ul li:last-child h3 {
  display: flex;
  padding-bottom: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.about .welcome ul li:last-child h3 span {
  display: inline;
  margin-left: 0.5rem;
}
.about .welcome ul li:last-child .home-welcome {
  display: none;
}
.about .welcome ul li:last-child .about-welcome {
  display: block;
}
.about .our-missions {
  padding: 2rem 0;
  position: relative;
}
.about .our-missions .outer-div {
  padding: 5rem 1rem;
  background: rgb(239, 243, 255);
  background: linear-gradient(90deg, rgb(239, 243, 255) 70%, rgb(255, 255, 255) 70%);
  position: relative;
}
.about .our-missions .outer-div h2 {
  font-size: 25px;
  font-weight: 600;
  color: "";
  line-height: 35px;
  letter-spacing: 0.05em;
  border-bottom: 1px solid #FBBA00;
  display: inline;
  padding-bottom: 0.25rem;
}
.about .our-missions .outer-div .inner-ul {
  padding-left: 0;
  width: 75%;
}
.about .our-missions .outer-div .inner-ul ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 0;
  margin-top: 1.5rem;
}
.about .our-missions .outer-div .inner-ul ul li:first-child {
  margin-right: 1.5rem;
}
.about .our-missions .outer-div .inner-ul ul li:last-child {
  width: 65%;
}
.about .our-missions .outer-div .inner-ul ul li:last-child h5 {
  font-size: 1.25rem;
  font-weight: 500;
  color: "";
  line-height: 30px;
  letter-spacing: 0.05em;
}
.about .our-missions .outer-div .inner-ul ul li:last-child p {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
}
.about .our-missions .half-img {
  width: 45%;
  height: auto;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin: 0;
}
.about .our-missions .half-img img {
  width: 100%;
}
.about .our-missions .our-mission {
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 50%;
}
.about .why-choose-us {
  padding: 2rem 0;
}
.about .why-choose-us .outer-div {
  position: relative;
}
.about .why-choose-us .outer-div figure {
  width: 60%;
}
.about .why-choose-us .outer-div .our-mission {
  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: #fff;
  width: 50%;
  box-shadow: 0px 12px 56px rgba(6, 28, 61, 0.08);
}
.about .why-choose-us .outer-div .our-mission .faq-accordion {
  display: block;
  padding: 1rem 0;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion h5 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item:first-of-type {
  border-top: 0;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item .accordion-header {
  font-size: 1.25rem;
  font-weight: 500;
  color: "";
  line-height: 30px;
  letter-spacing: 0.05em;
  font-family: "Roboto", sans-serif;
  display: block;
  border-bottom: none;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item .accordion-header .accordion-button {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  line-height: 30px;
  letter-spacing: 0.05em;
  padding-left: 15px;
  border-radius: 0;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item .accordion-body {
  padding: 15px;
  background-color: #EEF3FF;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item .accordion-body p {
  font-size: 1rem;
  font-weight: 400;
  color: "";
  line-height: 26px;
  letter-spacing: 0.05em;
  margin-bottom: 0;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion:last-child .accordion-item {
  border-bottom: 0;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item {
  border-left: none;
  border-right: none;
  background-color: #FBFBFB;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 1.5rem 0 1.5rem;
  border-color: rgba(222, 226, 230, 0.4509803922);
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item .accordion-body {
  font-size: 0.875rem;
  font-weight: 400;
  color: "";
  line-height: 26px;
  letter-spacing: 0.05em;
  padding: 1rem;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-item .accordion-body table td {
  padding-right: 6px;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-button:not(.collapsed) {
  background-color: #3153A5;
  color: #fff !important;
  box-shadow: none;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-button {
  font-size: 1.25rem;
  font-weight: 400;
  color: "";
  line-height: 1rem;
  letter-spacing: 0.05em;
  padding-left: 6px;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-button:focus {
  box-shadow: none;
  border-color: transparent;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-button:after {
  background-image: none;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion button.accordion-button.collapsed span.minusicon {
  display: none;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion button.accordion-button.collapsed span.plusicon {
  display: block;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion button.accordion-button span.plusicon {
  display: none;
  color: #000;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion span.plusicon, .about .why-choose-us .outer-div .our-mission .faq-accordion span.minusicon {
  position: absolute;
  right: 1rem;
  color: #fff;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion span.minusicon {
  margin-right: 0.4rem;
}
.about .why-choose-us .outer-div .our-mission .faq-accordion .accordion-button:not(.collapsed)::after {
  background-image: none;
}

.services .bread {
  background-color: #eff5fb;
}
.services .services-section {
  padding: 1rem 0 8rem;
  background: url(../images/our-services-banner.png) no-repeat bottom;
  background-size: cover;
  margin-bottom: 5rem;
}
.services .services-section h2 {
  padding-bottom: 0;
  margin: 0 auto 2.5rem;
}
.services .services-section .services-main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.services .services-section .services-main a {
  width: 24%;
  margin-right: 1rem;
  margin-bottom: 3rem;
}
.services .services-section .services-main a .card {
  border-top: 5px solid #FBBA00;
  position: relative;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0;
}
.services .services-section .services-main a .card .ellipse {
  width: 150px;
}
.services .services-section .services-main a .card .icon {
  position: absolute;
  top: 2.5rem;
  left: 2rem;
}
.services .services-section .services-main a .card .card-body {
  border-radius: 0;
  padding: 1rem 2rem;
  height: 335px;
}
.services .services-section .services-main a .card .card-body h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  line-height: 30px;
  letter-spacing: 0.05em;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
}
.services .services-section .services-main a .card .card-body span {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
}
.services .services-section .services-main a:nth-child(4), .services .services-section .services-main a:last-child {
  margin-right: 0;
}
.services .services-section .services-main .book-card {
  margin-right: 0;
  width: 24%;
  margin-bottom: 3rem;
  border: none;
  border-radius: 0;
}
.services .services-section .services-main .book-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 2rem;
}
.services .services-section .services-main .book-card .card-body h3 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #3153A5;
  line-height: 30px;
  letter-spacing: 0.05em;
  margin-bottom: 1.5rem;
}
.services .services-section .services-main .book-card .card-body .btn-yellow {
  margin: 0 0 1rem;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #FBBA00;
  border: 1px solid #FBBA00;
  padding: 0.75rem 1.25rem;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-wrap: nowrap;
}
.services .services-section .services-main .book-card .card-body .btn-yellow:focus {
  outline: none;
  box-shadow: none;
}
.services .services-section .services-main .book-card .card-body .btn-yellow:hover {
  color: #FBBA00;
  border: 1px solid #FBBA00;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.services .services-section .services-main .book-card .card-body .btn-yellow:hover img {
  filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
  transition: all 0.5s ease-in-out;
}
.services .services-section .services-main .book-card .card-body .btn-yellow img {
  margin-right: 10px;
}
.services .services-section .services-main .book-card .card-body ul {
  padding-left: 0;
  display: flex;
}
.services .services-section .services-main .book-card .card-body ul li img {
  margin-right: 0.5rem;
}
.services .services-section .services-main .book-card .card-body ul li a {
  display: block;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  line-height: 19px;
  letter-spacing: 0.05em;
  width: auto;
}

.clients .clients-section {
  padding: 1rem 0 4rem;
}
.clients .clients-section h2 {
  padding-bottom: 0;
  margin: 0 auto 2.5rem;
}
.clients .clients-section .clients-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.clients .clients-section .clients-main .card {
  position: relative;
  width: 32%;
  margin-bottom: 3rem;
  border: none;
  box-shadow: 0px 12px 40px rgba(6, 28, 61, 0.04);
}
.clients .clients-section .clients-main .card .card-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  position: relative;
  padding-left: 60px;
  margin: 1rem;
}
.clients .clients-section .clients-main .card .card-body img {
  margin-right: 1.5rem;
  position: absolute;
  left: 0;
}
.clients .clients-section .clients-main .card .card-body h3 {
  font-size: 1rem;
  font-weight: 600;
  color: "";
  line-height: 26px;
  letter-spacing: 0.05em;
  margin-bottom: 0;
}

.contact .contact-section {
  padding: 1rem 0 4rem;
}
.contact .contact-section .outer-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.contact .contact-section .outer-div .get-in-touch {
  padding: 5rem 3rem;
  width: 40%;
  height: 750px;
  background: url(../images/get-in-touch-bg.png) no-repeat center;
  background-size: cover;
}
.contact .contact-section .outer-div .get-in-touch h2 {
  font-size: 35px;
  font-weight: 500;
  color: #FBBA00;
  line-height: 50px;
  letter-spacing: 0.05em;
  margin: 0 0 2rem;
}
.contact .contact-section .outer-div .get-in-touch .footer-link {
  padding-left: 0;
}
.contact .contact-section .outer-div .get-in-touch .footer-link li {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-wrap: nowrap;
}
.contact .contact-section .outer-div .get-in-touch .footer-link li .Icon {
  margin-right: 1rem;
  font-size: 16px;
}
.contact .contact-section .outer-div .get-in-touch .footer-link li div h5 {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  border-bottom: 1px solid #FBBA00;
  display: table;
  opacity: 0.8;
  padding-bottom: 0.1rem;
}
.contact .contact-section .outer-div .get-in-touch .footer-link li div a {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  line-height: 26px;
  letter-spacing: 0.05em;
  text-decoration: none;
  display: block;
  margin-bottom: 0.25rem;
}
.contact .contact-section .outer-div .get-in-touch .footer-link li div p {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  line-height: 26px;
  letter-spacing: 0.05em;
  display: block;
  width: 75%;
}
.contact .contact-section .outer-div .get-in-touch .connect {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.contact .contact-section .outer-div .get-in-touch .connect hr {
  width: 32px;
  border-top: 2px solid #FBBA00;
  opacity: 1;
  margin-right: 1rem;
}
.contact .contact-section .outer-div .get-in-touch .connect h5 {
  font-size: 1rem;
  font-weight: 400;
  color: #FBBA00;
  line-height: 24px;
  letter-spacing: 0.05em;
  margin-bottom: 0;
}
.contact .contact-section .outer-div .get-in-touch .social-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0;
}
.contact .contact-section .outer-div .get-in-touch .social-link li {
  margin-right: 1.5rem;
}
.contact .contact-section .outer-div .get-in-touch .social-link li img {
  width: 40px;
  height: 40px;
}
.contact .contact-section .outer-div .contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: "";
  flex-wrap: wrap;
  flex-wrap: nowrap;
  background-color: #F5F6FA;
  width: 60%;
  height: 750px;
  position: relative;
  padding: 5rem 3rem;
}
.contact .contact-section .outer-div .contact-form .ellipse {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
}
.contact .contact-section .outer-div .contact-form h2 {
  font-size: 35px;
  font-weight: 500;
  color: "";
  line-height: 50px;
  letter-spacing: 0.05em;
  font-family: "Jost", sans-serif;
  border-bottom: 1px solid #FBBA00;
  display: table;
  margin: 0 auto;
}
.contact .contact-section .outer-div .contact-form p {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 2rem;
}
.contact .contact-section .outer-div .contact-form .form {
  display: block;
  width: 100%;
}
.contact .contact-section .outer-div .contact-form .form .form-group label {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
  margin-bottom: 0;
}
.contact .contact-section .outer-div .contact-form .form .form-group .cust-select {
  display: flex;
  position: relative;
}
.contact .contact-section .outer-div .contact-form .form .form-group .cust-select .std-code {
  width: 75px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  border: 1px solid #D9D9D9;
  font-size: 0.875rem;
  border-radius: 0.5px;
  border-right: 0;
  height: 40px;
}
.contact .contact-section .outer-div .contact-form .form .form-group .cust-select .formMargin {
  width: 89%;
}
.contact .contact-section .outer-div .contact-form .form .form-group .cust-select .error-message {
  margin-left: -75px;
}
.contact .contact-section .outer-div .contact-form .form .form-group .lbl {
  display: none;
}
.contact .contact-section .outer-div .contact-form .form .form-group .form-control {
  border: 1px solid #D9D9D9;
  font-size: 0.875rem;
  border-radius: 0.5px;
}
.contact .contact-section .outer-div .contact-form .form .form-group .form-control.txtbox {
  padding: 12px;
  height: 40px;
}
.contact .contact-section .outer-div .contact-form .form .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.contact .contact-section .outer-div .contact-form .form .form-group .error-message {
  font-size: 0.75rem;
  font-weight: 400;
  color: #D90312;
  line-height: 26px;
  letter-spacing: 0.05em;
}
.contact .contact-section .outer-div .contact-form .form .form-group textarea {
  width: 100%;
  background-color: #fff;
  padding: 12px;
  font-size: 0.875rem;
  resize: none;
  border: 1px solid #d9d9d9;
  border-radius: 0.5px;
}
.contact .contact-section .outer-div .contact-form .form .form-group textarea:focus-visible {
  outline: none;
}
.contact .contact-section .outer-div .contact-form .submit {
  text-align: center;
  margin-top: 1.5rem;
}
.contact .contact-section .outer-div .contact-form .submit .btn-yellow {
  width: 350px;
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #FBBA00;
  border: 1px solid #FBBA00;
  font-size: 0.875rem;
  font-weight: 800;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
}
.contact .contact-section .outer-div .contact-form .submit .btn-yellow:focus {
  outline: none;
  box-shadow: none;
}
.contact .contact-section .outer-div .contact-form .submit .btn-yellow:hover {
  color: #FBBA00;
  border: 1px solid #FBBA00;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.contact .contact-section .outer-div .contact-form .submit .btn-yellow:hover img {
  filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
  transition: all 0.5s ease-in-out;
}
.contact .contact-section .outer-div .contact-form .submit .btn-yellow:focus {
  outline: none;
}

.book-an-appointment .main-div {
  background: url(../images/book-appointment-bg.png) no-repeat center;
  background-size: cover;
}
.book-an-appointment .main-div .book-an-appointment-section {
  padding: 2rem 0 4rem;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form {
  background-color: #F5F6FA;
  width: 65%;
  position: relative;
  margin: auto;
  padding: 4rem 5rem 5rem;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .ellipse {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form h2 {
  font-size: 35px;
  font-weight: 500;
  color: "";
  line-height: 50px;
  letter-spacing: 0.05em;
  font-family: "Jost", sans-serif;
  border-bottom: 1px solid #FBBA00;
  display: table;
  margin: 0 auto;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form p {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 2rem;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form {
  display: block;
  width: 100%;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group label {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
  margin-bottom: 0;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .cust-select {
  display: flex;
  position: relative;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .cust-select .std-code {
  width: 75px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  border: 1px solid #D9D9D9;
  font-size: 0.875rem;
  border-radius: 0.5px;
  border-right: 0;
  height: 40px;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .cust-select .formMargin {
  width: 89%;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .cust-select .error-message {
  margin-left: -75px;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .lbl {
  display: none;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .form-control {
  border: 1px solid #D9D9D9;
  font-size: 0.875rem;
  border-radius: 0.5px;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .form-control.txtbox {
  padding: 12px;
  height: 40px;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .error-message {
  font-size: 0.75rem;
  font-weight: 400;
  color: #D90312;
  line-height: 26px;
  letter-spacing: 0.05em;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group textarea {
  width: 100%;
  background-color: #fff;
  padding: 12px;
  font-size: 0.875rem;
  resize: none;
  border: 1px solid #d9d9d9;
  border-radius: 0.5px;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group textarea:focus-visible {
  outline: none;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .submit {
  text-align: center;
  margin-top: 1.5rem;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .submit .btn-yellow {
  width: 350px;
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #FBBA00;
  border: 1px solid #FBBA00;
  font-size: 0.875rem;
  font-weight: 800;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .submit .btn-yellow:focus {
  outline: none;
  box-shadow: none;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .submit .btn-yellow:hover {
  color: #FBBA00;
  border: 1px solid #FBBA00;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .submit .btn-yellow:hover img {
  filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
  transition: all 0.5s ease-in-out;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .submit .btn-yellow:focus {
  outline: none;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .calender {
  position: absolute;
  z-index: 1;
  top: 3rem;
  left: 3rem;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form-group .form-select {
  border-radius: 5px;
  height: 40px;
  border: 1px solid #D9D9D9;
  font-size: 0.875rem;
  border-radius: 0.5px;
  background-image: url("../images/icons/down-caret.svg");
  background-size: 10px;
  background-position: top 16px right 17px;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form-group .form-select:focus {
  box-shadow: none;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .select .form-group {
  width: 49%;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .select .form-group .formMargin .lbl {
  display: none;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .select .form-group .form-control {
  border: 1px solid #D9D9D9;
  font-size: 0.75rem;
  border-radius: 0.5px;
  height: 40px;
  margin-right: 5px;
  padding-left: 10px;
  background-image: url("../images/icons/down-caret.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: top 16px right 17px;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form input[type=text] ::-webkit-input-placeholder {
  font-size: 0.875rem;
}
.book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form input[type=time]::-webkit-calendar-picker-indicator {
  background-image: url("../images/icons/down-caret.svg");
  background-size: 10px;
  background-position: top 16px right 17px;
}

.book-appointment {
  width: 25%;
}
.book-appointment .card {
  border: none;
}
.book-appointment .card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.5rem;
  border: 1px solid #dee2e6;
}
.book-appointment .card .card-body h5 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #3153A5;
  line-height: 30px;
  letter-spacing: 0.05em;
  margin-bottom: 1.5rem;
}
.book-appointment .card .card-body .btn-yellow {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.05em;
  background: #FBBA00;
  border: 1px solid #FBBA00;
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-wrap: nowrap;
}
.book-appointment .card .card-body .btn-yellow:focus {
  outline: none;
  box-shadow: none;
}
.book-appointment .card .card-body .btn-yellow:hover {
  color: #FBBA00;
  border: 1px solid #FBBA00;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.book-appointment .card .card-body .btn-yellow:hover img {
  filter: invert(40%) sepia(85%) saturate(1215%) hue-rotate(2deg) brightness(105%) contrast(103%);
  transition: all 0.5s ease-in-out;
}
.book-appointment .card .card-body .btn-yellow img {
  margin-right: 10px;
}
.book-appointment .card .card-body ul {
  padding-left: 0;
  display: flex;
  margin-bottom: 0;
}
.book-appointment .card .card-body ul li img {
  margin-right: 0.5rem;
}
.book-appointment .card .card-body ul li a {
  display: block;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  line-height: 19px;
  letter-spacing: 0.05em;
}
.book-appointment .card .card-body ul li::marker {
  color: red;
  font-size: 1.5em;
}

.air-condition .ac {
  padding: 1rem 0;
  margin-bottom: 5rem;
}
.air-condition .ac .ac-details {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: "";
  align-items: flex-start;
  flex-wrap: wrap;
  flex-wrap: nowrap;
}
.air-condition .ac .ac-details .content {
  padding-right: 5rem;
  width: 75%;
}
.air-condition .ac .ac-details .content h2 {
  padding-bottom: 0;
  margin-left: 0;
  text-align: left;
}
.air-condition .ac .ac-details .content p {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
}
.air-condition .ac .ac-details .content ul {
  margin-bottom: 3rem;
}
.air-condition .ac .ac-details .content ul li {
  list-style-type: disc;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 26px;
  letter-spacing: 0.05em;
}
.air-condition .ac .ac-details .content ul li span {
  font-weight: 700;
}/*# sourceMappingURL=style.css.map */