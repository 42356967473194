$maincolor: #FBBA00;
$secondarycolor:#3153A5;
$whitecolor: #fff;
$blackcolor: #000;
$bgColor: #f7fafd;
// $linkcolor: #5B005D;
//$hovercolor: #830069;
// $hovercolor:#C5017C;
// $texthover: #53C9C5;
// $offwhitecolor: #F9F9FA;
// $grayColor:#5D5D5D;

$basefont: 'Roboto', sans-serif;
$jostFont: 'Jost', sans-serif;
$basefontsize:1rem; 
$font12:0.75rem; // 12px
$font13:0.813rem; // 13px
$font14:0.875rem;// 14px
$font20:1.25rem; // 20px
$font32:2rem; // 32px
$font40:2.5rem; // 40px
$font50:3.125rem; // 50px


$DefaultTransition: all 0.5s ease-in-out;

// $iconcolor: #F6F6F6;
// $bordercolor: #B6B5B5;
// $redcolor: #FF0000;
// $cardcolor: #E6E6E6;
// $bluecolor: #043F7D;
// $headercolor: #7d6d53;
// $filtertext: #FCFCFC;
// $placeholdertext: #7F7F7F;
// $myborderclr:#DBDBDB;
// $OrangeColor:#F9A52E;
