@media screen and (min-width: 1920px) {
    .about .why-choose-us .outer-div .our-mission{right: 15%;}
}

@media screen and (max-width: 1366px) {
    .home .home-banner figure figcaption{left: 0%;transform: translate(10%, -20%);}
    .home .safety-measures ul li h5{left: 0;text-align: center;}
    .home .our-services .slider-container,.home .brands-we-serve .slider-container{padding: 0 3rem;}
    .home .safety-measures ul{justify-content: space-around;}
    .container, .container-lg, .container-md, .container-sm{max-width: 1296px !important;}
    footer .footer-list > li.social,footer .footer-list > li:last-child{width: 26%;}
    footer .footer-list > li:nth-child(2){width: 48%;}
    .welcome ul li:last-child p{margin-bottom: 0.5rem;}
    .services .services-section .services-main a .card .card-body{height: 365px;}
}

@media screen and (max-width: 1280px) {
    header nav #navbarSupportedContent .inner-nav{justify-content: space-around;flex-wrap: nowrap;}
    .welcome ul li:last-child h5{margin-bottom: .5rem;}
    .home .welcome ul li:last-child .home-welcome .btn-yellow{margin-top: 1rem;}
    footer .footer-list > li.social,footer .footer-list > li:last-child{width: auto;}
    footer .footer-list{justify-content: space-between;}
    .services .services-section .services-main a{width: 23%;}
    .btn-yellow{max-width: 100% !important;}
    .services .services-section .services-main a:last-child .card .card-body{padding: 1rem 2rem;}
    .welcome ul li:last-child p{margin-bottom: 0.25rem;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .form .form-group .cust-select .std-code{border-bottom: 2px solid #D9D9D9;}
    .about .welcome ul li:last-child .about-welcome p{font-size: 15px;}
    .about .welcome ul li:last-child h3{padding-bottom: .5rem;}
    .home .our-services .slick-slider .slick-slide{padding: 21px 15px;}
    .services .services-section .services-main a .card .card-body{height: 395px;}
}

@media screen and (max-width: 1199px) {
    .banner figure,.home .home-banner figure{height: 650px;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .ellipse{width: 175px;height: 75px;}
    .home .safety-measures ul li h5{font-size: 1.25rem;}
    .about .our-missions .outer-div .inner-ul ul li:last-child{width: 60%;}
} 

@media screen and (max-width: 1024px) {
    header nav #navbarSupportedContent .inner-nav .nav .bottom-nav .nav-item{margin: 0 1rem;}
    .welcome ul li:last-child h5{margin-bottom: 0;}
    .home .welcome ul li:last-child .home-welcome .btn-yellow{margin-top: 0;margin-bottom: 0;}
    .home .home-banner figure figcaption h1{font-size: 2.5rem;}
    .home .home-banner figure figcaption{transform: translate(10%, -35%);}
    .banner figure,.home .home-banner figure{height: 600px;}
    .thank-you .thank{margin-bottom: 0;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .ellipse{width: 140px;height: 140px;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .calender{top: 2.5rem;left: 2.5rem;width: 50px;}
    .services .services-section .services-main a,.services .services-section .services-main .book-card{width: 31.7%;}
    .services .services-section .services-main a:nth-child(4){margin-right: 1rem;}
    .home .our-services a .card .card-body{height: 355px;}
    .home .safety-measures{padding: 4rem 0;}
    .home .our-services a h2,.home .welcome ul li:last-child h3,.home .brands-we-serve h2,.home .safety-measures h2{font-size: 1.75rem;}
    .home .welcome ul li:last-child h3{line-height: 40px;}
    .home .welcome ul li:last-child .home-welcome p{font-size: 1rem;}
    .about .welcome ul li:last-child h3{font-size: 24px;    margin-bottom: 0.5rem;}
    .welcome ul li:first-child figure{padding-right: 0;}
    .welcome ul li:last-child p,.about .welcome ul li:last-child .about-welcome p{font-size: 14px;}
    .services .services-section .services-main{justify-content: flex-start;}
    .contact .contact-section .outer-div .contact-form .ellipse{width: 175px;}
    .welcome ul li:last-child p{margin-bottom: 1rem;}
    .about .welcome ul li:last-child .about-welcome p{margin-bottom: 0;}
    .home .welcome ul li:last-child h3{padding-bottom: 1rem;}
    .home .our-services .slick-slider .slick-slide{padding: 24px 15px;}
    .air-condition .ac .ac-details .content h2{font-size: 26px;}
    .air-condition .ac .ac-details .content{width: 70%;padding-right: 3rem;}
    .book-appointment{width: 30%;}
    .services .services-section .services-main a .card .card-body{height: 340px;}
}

@media screen and (max-width: 991px) {
    .home .safety-measures ul li h5 {font-size: 1rem;line-height: 26px;}
    .welcome ul li:last-child h3 span{display: inline;}
    .home .safety-measures ul li img{width: 35%;}
    .home .safety-measures ul li{width: 175px;height: 175px;}
    .home .our-services a .card .card-body{height: 360px;}
    footer .footer-list > li:last-child .ContactFooter .footer-link li{font-size: 14px;}
    footer .footer-list > li.social{width: 15%;}
    footer .footer-list > li.social .Footerlogo img{width: 100%;}
    footer .footer-list > li:last-child .ContactFooter .footer-link li .Icon,footer .footer-list > li:nth-child(2) ul .AddressFooter .address li{margin-right: 14px;}
    footer .footer-list > li:nth-child(2){width: 45%;}
    footer .footer-list > li:nth-child(2) ul .AddressFooter .social-link li img{width: 35px;height: 35px;}
    .navbar-light .navbar-toggler{box-shadow: none;}
    .thank-you .thank .thankyou{max-width: 100%;}
    .banner figure,.home .home-banner figure{height: 600px;}
    header nav{padding-bottom: .5rem !important;}
    footer .copyright .container{justify-content: space-between;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form{width: 100%;padding: 3rem;}
    .contact .contact-section .outer-div .get-in-touch,.contact .contact-section .outer-div .contact-form{width: 50%;padding: 2rem;height: 700px;}
    .contact .contact-section .outer-div .contact-form .ellipse{width: 100px;}
    .contact .contact-section,.clients .clients-section{padding: 1rem 0 2rem;}
    .clients .clients-section .clients-main .card{width: 48%;}
    .clients .clients-section .clients-main .card .card-body img{margin-right: 1rem;}
    .welcome ul li,.about .our-missions .outer-div,.about .why-choose-us .outer-div figure,.about .why-choose-us .outer-div figure img,.about .our-missions .outer-div .inner-ul{width: 100%;}
    .about .our-missions .outer-div{background: linear-gradient(90deg, rgb(239, 243, 255) 100%, rgb(255, 255, 255) 100%);padding: 2rem 1rem;}
    .welcome ul li:first-child{margin-bottom: 1rem;}
    .about .our-missions .our-mission,.about .why-choose-us .outer-div .our-mission{width: 100%;position: relative;transform: none;}
    .about .our-missions .outer-div .inner-ul ul{justify-content: space-between;}
    .about .our-missions .outer-div .inner-ul ul li:last-child{width: 89%;}
    .services .services-section .services-main{justify-content: space-between;}
    .services .services-section .services-main a,.services .services-section .services-main .book-card{width: 48%;margin-right: 0 !important;}

    .nav .top-nav img{margin-right: 10px;}
    .nav .bottom-nav{flex-wrap: wrap;justify-content: center;align-items: flex-start;margin-right: 0 !important;}
    .nav .top-nav{margin-bottom: 0;}
    .nav .top-nav .nav-item{justify-content: flex-start;margin-right: 0;}
    .nav .bottom-nav .nav-item{margin: 0;margin-top: 1rem;}
    .nav .top-nav .nav-link,.nav .bottom-nav .nav-item .nav-link{color: #fff;}
    .nav .top-nav .nav-link span{color: #fff;margin: 0 0.5rem;}
    .home .welcome ul li:last-child h3{display: flex;margin-bottom: 1rem;}
    .home .welcome ul li:last-child h3 span{display: inline;margin-left: .5rem;}
    .about .welcome ul li:last-child h3{padding-bottom: 1rem;margin-bottom: 1rem;}
    .welcome ul li:last-child p, .about .welcome ul li:last-child .about-welcome p{font-size: 1rem;}
    .welcome ul li:first-child{padding-right: 0;}
    .welcome ul li:last-child{padding-left: 0;}
    .home .welcome,.home .quality-staff,.home .safety-measures{padding: 2rem 0;}
    .half-img{position: relative;top: 0;transform: none;width: 100%;}
    .about .welcome ul li:last-child .about-welcome p{margin-bottom: .5rem;}
    .air-condition .ac .ac-details .content{width: 60%;padding-right: 1rem;}
    .book-appointment{width: 40%;}
    .air-condition .ac .ac-details .content h2{line-height: 40px;}
    .services .services-section .services-main a .card .card-body{height: 310px;}
}

@media screen and (max-width: 767px) {
    .banner figure,.home .home-banner figure{height: 500px;}
    footer .footer-list > li{width: 100% !important;margin-bottom: 1rem;}
    footer .footer-list > li.social .Footerlogo{text-align: center;}
    footer .footer-list > li.social .Footerlogo img{width: auto;max-width: 100%;}
    footer .footer-list > li:last-child{margin-bottom: 0;}
    footer .copyright .container{justify-content: center;}
    .thank-you .thank .thankyou .icon{width: 150px;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .ellipse{width: 100px;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .calender {top: 2rem;left: 2rem;width: 30px;}
    footer .footer-list > li:last-child .ContactFooter .footer-link{display: flex;flex-wrap: wrap;}
    footer .footer-list > li:last-child .ContactFooter .footer-link li{width: 50%;}
    .contact .contact-section .outer-div .get-in-touch, .contact .contact-section .outer-div .contact-form {width: 100%;height: auto;}
    .contact .contact-section .outer-div .get-in-touch .footer-link li:last-child p{margin-bottom: 0;}
    .about .our-missions .outer-div .inner-ul ul li:last-child{width: 85%;}
    .about .welcome ul li:last-child h3{font-size: 22px;}
    .home .our-services a .card .card-body{height: 295px;}
    .air-condition .ac .ac-details .content,.book-appointment{width: 100%;}
    .air-condition .ac .ac-details{flex-direction: column;}
    .air-condition .ac .ac-details .content h2{font-size: 23px;}
    .air-condition .ac .ac-details .content{padding-right: 0;}
    .book-appointment .card .card-body .btn-yellow{width: auto;}
    .services .services-section .services-main a .card .card-body{height: 260px;}
}

@media screen and (max-width: 599px) {
    .banner figure{height: 450px;}
    .home .home-banner figure{height: 550px;}
    .thank-you .thank .thankyou{padding: 1rem 1rem 2rem;}
    .thank-you .thank .thankyou p{font-size: 1rem;margin-bottom: 1rem;}
    .thank-you .thank .thankyou .icon{width: 90px;}
    .thank-you .thank .thankyou h2{margin-bottom: 2rem;}
    .about .our-missions .outer-div .inner-ul ul li:last-child{width: 81%;}
    .services .services-section .services-main a,.services .services-section .services-main .book-card .card-body{width: 100%;}
    .services .services-section{padding-bottom: 0;}
    .nav .top-nav img{margin-right: 10px;}
    .nav .top-nav{margin-bottom: 0;}
    .home .safety-measures ul{flex-direction: column;}
    .home .safety-measures ul li{margin-bottom: 3rem;}
    .home .safety-measures ul li:last-child{margin-bottom: 0;}
    .home .safety-measures ul li h5{align-items: center;top: 30%;}
    .home .home-banner figure figcaption h1{font-size: 1.5rem;line-height: 40px;}
    .home .home-banner figure figcaption h1 span{display: inline;}
    .home .home-banner figure figcaption{width: 90%;right: 0;left: 50%;transform: translateX(-50%);top: 30%;}
    .home .home-banner figure figcaption .btn-white,.home .home-banner figure figcaption .btn-yellow{display: none;}
    .home .home-banner figure .btn-white{position: absolute;background: #fff;border: 1px solid #FBBA00;padding: 0.75rem 1rem;border-radius: 5px;bottom: 0;right: 52px; border-top-right-radius: 0;border-bottom-right-radius: 0;}
    .home .home-banner figure .btn-yellow{position: absolute;background: #FBBA00;border: 1px solid #FBBA00;padding: 0.75rem 1rem;bottom: 0;right: 0;border-top-left-radius: 0;border-bottom-left-radius: 0;}
    .home .brands-we-serve h2{font-size: 1.5rem;}
    .navbar-toggler .logo .navbar-brand img{width: 100px;}
    .home .home-banner figure figcaption h1{padding-bottom: 0.5rem;margin-bottom: 1.5rem;}
    .home .our-services .slick-slider .slick-arrow.slick-next{right: -35px;}
    .home .our-services .slick-slider .slick-arrow.slick-prev{left: -35px;}
    .home .our-services .explore{margin-top: 1rem;}
    .home .welcome,.home .quality-staff{padding: 3rem 0;}
    .home .safety-measures ul li{width: 155px;height: 155px;}
    .about .welcome ul li:last-child h3{display: grid;}
    .about .welcome ul li:last-child h3 span{margin-left: 0;}
    .rcb-btn{top: 70%;}
    .home .home-banner .slick-slider .slick-arrow.slick-prev,.home .home-banner .slick-slider .slick-arrow.slick-arrow.slick-next{top: 60%;}
    .home .our-services a .card .card-body{height: 470px;}
    .air-condition .ac .ac-details .content h2{font-size: 18px;}
    .air-condition .ac .ac-details .content h2{line-height: 30px;}
    .services .services-section .services-main .book-card{width: 100%;}
    .services .services-section .services-main .book-card .card-body{padding: 2rem;}
    .services .services-section .services-main .book-card .card-body .btn-yellow{width: auto;}
}

@media screen and (max-width: 414px) {
    footer .copyright .container{justify-content: center;}
    footer .copyright li{text-align: center;}
    footer .copyright li span{display: block;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form{padding: 2rem;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form h2{font-size: 1.5rem;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .ellipse{width: 75px;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .calender {top: 1.5rem;left: 1.5rem;width: 20px;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .select .form-group,footer .footer-list > li:last-child .ContactFooter .footer-link li,.clients .clients-section .clients-main .card{width: 100%;}
    .about .our-missions .outer-div .inner-ul ul li:last-child{width: 80%;}
    .home .quality-staff ul li{width: 100%;height: 300px;}
    .home .quality-staff ul li img{object-fit: cover;height: 100%;}
    .welcome ul li:first-child figure,.welcome ul li:first-child figure img{height: auto;}
    .services .services-section .services-main a .card .card-body{height: 315px;}
}

@media screen and (max-width: 393px) {
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form{padding: 1.5rem;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .ellipse{width: 50px;}
    .book-an-appointment .main-div .book-an-appointment-section .book-an-appointment-form .calender{top: 1rem;left: 1rem;width: 15px;}
    .about .our-missions .outer-div .inner-ul ul li:last-child{width: 74%;}
}